import React, { useEffect, useState } from 'react'
import { ListaProveedor } from '../../Components/GestAdm/ListaProveedor'
import { Buscador } from '../../Components/Buscador'
import { Modal } from '../../Components/Modal'

import '../../CSS/Proveedor.css'
import { FormAgregarProveedor } from '../../Components/GestAdm/FormAgregarProveedor'
import { useGetproveedores } from '../../Hooks/useGetProveedores'

export const Proveedores = () => {

    const [showModal, setshowModal] = useState(false)

    const [filtroNombre, setFiltroNombre] = useState('');
    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const [Clientes, setClientes] = useState()


    let handleModal = () => {
        setshowModal(!showModal)
    }

    let { data, loading } = useGetproveedores()
   
    useEffect(() => {

        if (!loading) {
            setClientes(data);
            setProductosFiltrados(data);
        }

    }, [data, loading]);

    return (
        <div className='bloque-render'>
            <h2>Lista de Proveedores</h2>
            <hr />
            <br />
            <div className='element-botones-accion'>
                <button className='element-boton agregar-proveedor' onClick={handleModal}>agregar Proveedor</button>

                <Buscador
                    filtroNombre={filtroNombre}
                    setFiltroNombre={setFiltroNombre}
                    setProductosFiltrados={setProductosFiltrados}
                    Lista={Clientes}
                    placeholder='Buscar por Nombre de Proveedor'
                    Campo='nombre'
                />
            </div>
            <ListaProveedor Proveedores={productosFiltrados}/>

            <Modal showModal={showModal} closeModal={handleModal} >
                <FormAgregarProveedor handleModal={handleModal}/>
            </Modal>
        </div>
    )
}
