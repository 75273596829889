import React, { useEffect, useState } from 'react'
import { UseGetFacturas } from '../../Hooks/useGetFacturas';
import { TablaDespachos } from '../../Components/DespachosyFacturas/TablaDespachos';
import "../../CSS/Despachos.css"
export const Despachos = () => {
  const [Despachos, setDespachos] = useState([])
  let { data, loading } = UseGetFacturas()

  useEffect(() => {
      if (!loading) {
         

         // const arregloFiltrado = data.filter(objeto => objeto.estado === "Pendiente" || objeto.estado === "Pago Parcial");
        setDespachos(data);  
        
            
      }

  }, [loading]);
  return (
    <div className='bloque-render'>
    <h2>Despachos</h2>
    <hr />
    <br /> 
    
    <TablaDespachos Despachos={Despachos}/>
    </div>
  )
}
