import React, { useEffect, useState } from 'react'
import { useGetStock } from '../../Hooks/useGetStock'
import { LoadingSpinner } from '../Spiner'
import { Modal } from '../Modal'
import { UpdateUsuario } from '../../Helpers/UpdateStock'
import Popup from '../PopUpNotificacion'

export const InsertNewStock = ({ id, handleModal, item }) => {

  let { data, loading } = useGetStock(id)
  const [showPopup, setShowPopup] = useState(false);
  const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })
  const [Data, setdata] = useState([])
  const [showModalGuardar, setshowModalGuardar] = useState(false)
  const [cantidad, setcantidad] = useState(0)
  const [posicion, setposicion] = useState(0)
  const [subposicion, setsubposicion] = useState(0)
  const [wait, setwait] = useState(false)
  useEffect(() => {
    if (!loading) {
      setdata(data)
    }
  }, [data, loading])

  let handleshoweGuardar = (cantidad, index, subindex) => {
    setposicion(index)
    setcantidad(cantidad)
    setsubposicion(subindex)
    setshowModalGuardar(!showModalGuardar)
  }

  let submitProducts = async () => {

    let nuevaAdquisicion = { ...Data[posicion] }
    nuevaAdquisicion.productos[subposicion] = { ...Data[posicion].productos[subposicion], cargado: true }
    let body = { id: item._id, cantidad, data: nuevaAdquisicion ,detalle:nuevaAdquisicion.productos[subposicion]}
   
 
    let res = await UpdateUsuario(body)
    
    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: "Actualizacion realizada con exito" })
    if (res.succes === false) {
      setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    setTimeout(() => {
      setShowPopup(false);
    }, 4000);
    return res.succes && window.location.reload(true)
  }


  return (
    <>
      {loading || wait ? <div style={{ width: '700px' }}><LoadingSpinner /></div> :

        <div>
          {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
          <span>Ingreso de Stock</span>

          <hr />
          <br />
          <table className='Tablaproducto'>
            <thead>
              <tr>
                <td>Número Operación</td>
                <td>Nombre Producto</td>
                <td>Estado Adquisición</td>
                <td>Stock en Tránsito</td>
                <td>Stock Disponible</td>


              </tr>
            </thead>
            <tbody>

              {Data?.map((item, index) => (
                item?.productos?.map((subitem, subindex) => (

                  <>
                    {subitem.Producto === id && subitem.cargado !== true ? <tr key={`${index}${subindex}`}><td>{item.noperacion}</td>
                      <td>{subitem.Producto}</td>
                      <td>{item.estado}</td>
                      <td>{subitem.Cantidad}</td>
                      <td><span className='elemet-span-agregar' onClick={() => handleshoweGuardar(subitem.Cantidad, index, subindex

                      )}>Agregar</span></td></tr> :
                      <></>
                    }
                  </>
                ))
              ))}

            </tbody>
          </table>


          <Modal showModal={showModalGuardar} closeModal={handleshoweGuardar}>
            <div className='bloque-adv-eliminar'>
            <strong>Se agregarán {parseInt(cantidad).toLocaleString('ES-CL')} productos al Stock, ¿desea continuar?</strong>
                <br />
                <br />
                <div className='element-save-bar'>
                  <button className="element-boton cerrar" onClick={() => setshowModalGuardar(!showModalGuardar)}>Cerrar</button>
                  <button className="element-boton guardar" onClick={submitProducts}>Agregar</button>
                </div>


            </div>
          </Modal>


        </div>}
    </>
  )
}
