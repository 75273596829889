import React, { useState } from 'react'
import { Modal } from '../Modal'

import { POSTAction } from '../../Helpers/POST'
import Popup from '../PopUpNotificacion'
import { LectorBarras } from './LectorBarras'




export const TablaBodega = ({ Bodega ,setBodega}) => {

  
  const [showPopup, setShowPopup] = useState(false);
  const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

  const [showeliminar, setshoweliminar] = useState(false)
  const [showpickear, setshowpickear] = useState(false)
  const [item, setitem] = useState([])
  const [Posicion, setPosicion] = useState('')

  let handleModaleliminar = (index) => {
    setshoweliminar(!showeliminar)
    setPosicion(index)
  }

  let handlepicking = (index) =>{
    setitem(Bodega[index])
    setshowpickear(!showpickear)
  }
  let handleeliminar = async () => {
   
    //let res=({succes:true})
   // let res=await POSTAction(Bodega[Posicion],'Conf/eliminarbanco')
let res={}
    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: " eliminado de forma exitosa" })
    if (!res.succes) {
        setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    setTimeout(() => {
        setShowPopup(false);
    }, 4000);

    let newArray = [...Bodega];
    newArray.splice(Posicion, 1);
    setBodega(newArray);
    
    return res.succes && setshoweliminar(!showeliminar)
  }

  return (
    <>
    {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <table className='Tablaproducto'>
        <thead><tr>
          <td>ID Pedido</td>
          {/* <td>Fecha</td> */}
          <td>Cargar Productos</td>
          {/* <td>Eliminar</td> */}
        </tr></thead>
        <tbody>
          {Bodega?.map((item, index) => (
            <tr key={index}>
              <td style={{width:"100px"}}>{item._id}</td>
              <td><button onClick={()=>{handlepicking(index)}} className='element-boton picking'>Cargar Productos</button></td>
            </tr>
          ))}
        </tbody>

      </table>
      <Modal showModal={showeliminar} closeModal={handleModaleliminar}>
        <div className='bloque-adv-eliminar'>

          <strong>Seguro que deseas eliminar el item?</strong>
          <br />
          <br />
          <div className='element-save-bar'>
            <button className="element-boton Holder" onClick={handleModaleliminar}>Cerrar</button>
            <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
          </div>

        </div>
      </Modal>
      <Modal showModal={showpickear} closeModal={handlepicking}>
        <LectorBarras item={item} handleModal={handlepicking} />
      </Modal> 
    </>
  )
}
