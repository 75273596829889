import React, { useState } from 'react'
import { formatNumber } from '../../Utility/formarnumber';
import { CrearProducto } from '../../Helpers/CreateProducto';
import Popup from '../PopUpNotificacion';
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
// Función para validar si todos los campos tienen datos
const validateFields = (data) => {
    // Verificar si todos los campos están llenos
    const fields = [
      'codigo',
      'nombre',
      'familia',
      'descripcion',
      'descripcion2',
      'precioneto',
      'stock',
      'stockvirtual',
      'stocktransito',
      'formato',
      'procedencia',
      'volumen',
      'peso',
      'estado',
    ];
  
    for (const field of fields) {
      if (Array.isArray(data[field])) {
        // Si el campo es un array, verificar que no esté vacío
        if (data[field].length === 0) {
          return `${field} no puede estar vacío`;
        }
      } else if (data[field] === null || data[field] === undefined || data[field].toString().trim() === '') {
        // Verificar si el campo es null, undefined o vacío
        return `${field} no puede estar vacío`;
      }
    }
  
    return null; // Todos los campos están llenos
  };
let Inputform = ({ titulo, name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>

                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}

                />

            </label>
        </>)
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}:</label>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}

export const AgregarProducto = ({ handleModal,productos,setProductos }) => {

    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

    const [producto, setproducto] = useState({
        "codigo": "",
        "nombre": "",
        "familia": "",
        "descripcion": " ",
        "descripcion2": "",
        "precioneto": "",
        "stock": 0,
        "stockvirtual": 0,
        "stocktransito": 0,
        "Detalle": [],
        "formato": "",
        "procedencia": "",
        "volumen": "",
        "peso": "",
        "comnacional": "",
        "ean-13": "",
        "ean-14": "",
        estado:'Visible'})

    let handleclose = () => {
        handleModal()
    }
    let addproduct = async() => {
       
        const validationError = validateFields(producto);
        if (validationError) {
            setmsgpopup({ tipo: 'error', msg:"Existen campos vacios dentro del formulario" });
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 4000);
        } else {
            setmsgpopup("");
          // Procesar datos o realizar alguna acción

        }

        let res=await CrearProducto(producto)

        setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "Shipper Creado de forma exitosa" })
        if (!res.succes) {
            setmsgpopup({ tipo: 'error', msg: res.estado })
            setTimeout(() => {
                setShowPopup(false);
            }, 4000);
        }else{
            setTimeout(() => {
                setShowPopup(false);
            }, 4000);
            await sleep(1000);
            setProductos([producto,...productos])
            setproducto({
                "codigo": "",
                "nombre": "",
                "familia": "",
                "descripcion": " ",
                "descripcion2": "",
                "precioneto": "",
                "stock": 0,
                "stockvirtual": 0,
                "stocktransito": 0,
                "Detalle": [],
                "formato": "",
                "procedencia": "",
                "volumen": "",
                "peso": "",
                "comnacional": "",
                "ean-13": "",
                "ean-14": "",
                estado:'Visible'})
                return res.succes && handleModal();

        }
   
    }

    return (
        <div>
{showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
            <h3 style={{ paddingTop: '10px' }}>Agregar Proveedor y Producto</h3>
            <br />
            <hr />

            <div className='element-input-Agregar'>
                <Inputform
                    titulo='Codigo-SKU'
                    name='codigo'
                    type='text'
                    value={producto.codigo}
                    setform={setproducto}
                    formulario={producto}
                />
                <Inputform
                    titulo='Nombre'
                    name='nombre'
                    type='text'
                    value={producto.nombre}
                    setform={setproducto}
                    formulario={producto}
                />
                <Inputform
                    titulo='Descripcion'
                    name='descripcion'
                    type='text'
                    value={producto.descripcion}
                    setform={setproducto}
                    
                    formulario={producto}
                />
                <Inputform
                    titulo='Descripcion 2'
                    name='descripcion2'
                    type='text'
                    value={producto.descripcion2}
                    setform={setproducto}
                    formulario={producto}
                />
                <Inputform
                    titulo='Precio Neto'
                    name='precioneto'
                    type='text'
                    value={formatNumber(producto.precioneto)}
                    setform={setproducto}
                    formulario={producto}
                />
                <Inputform
                    titulo='Formato'
                    name='formato'
                    type='text'
                    value={producto.formato}
                    setform={setproducto}
                    formulario={producto}
                />
                  <Inputform
                    titulo='Procedencia'
                    name='procedencia'
                    type='text'
                    value={producto.procedencia}
                    setform={setproducto}
                    formulario={producto}
                />
                  <Inputform
                    titulo='Volumen'
                    name='volumen'
                    type='text'
                    value={producto.forvolumenmato}
                    setform={setproducto}
                    formulario={producto}
                />
                  <Inputform
                    titulo='Peso'
                    name='peso'
                    type='text'
                    value={producto.peso}
                    setform={setproducto}
                    formulario={producto}
                />
                  {/* <Inputform
                    titulo='Formato'
                    name='formato'
                    type='text'
                    value={producto.formato}
                    setform={setproducto}
                    formulario={producto}
                /> */}
                <Selectform
                    opciones={{ MedicPro: "MedicPro", EsteriPro: "EsteriPro" }}
                    name='familia'
                    titulo='Familia'
                    opcionSeleccionada={producto}
                    setOpcionSeleccionada={setproducto}

                />
            </div>
            <div className='bloque-botones-accion'>
                <button className='element-boton cerrar' onClick={handleclose}>Cerrar</button>
                <button className='element-boton add' onClick={addproduct}>Agregar</button>
            </div>
        </div>
    )
}
