import React, { useState } from 'react'
import { formatNumber } from '../../Utility/formarnumber'
import { Modal } from '../Modal';

export const 
TablaProductosVenta = ({productos,OrdenCompra,setOrdenCompra}) => {
  const [showModaleliminar, setshowModaleliminar] = useState(false)
  const [modalItem, setModalItem] = useState(null);

  let handleeliminar=()=>{

    const nuevosProductos = [...productos];
        // Eliminar el producto en el índice especificado
        nuevosProductos.splice(modalItem, 1);
        // Actualizar el estado de la lista
        setOrdenCompra({...OrdenCompra,Productos:nuevosProductos});
        setshowModaleliminar(!showModaleliminar);
    }
  const handleshoweliminar = (posicion) => {
    setModalItem(posicion)
    setshowModaleliminar(!showModaleliminar);
}
  return (
    <div>
        <table className='Tablaproducto'>
            <thead>
                <tr><td>Nombre</td>
                <td>Cantidad</td>
                <td>Valor Total IVA</td>
                <td>Valor Total</td>
                <td>Eliminar</td>
                  {/* <td>Porcentaje</td> */}
                  </tr>
            </thead>
            <tbody>
                {productos?.map((item,index)=>(
                  <tr key={index}>
                      <td>{item.nombre}</td>
                      <td>{parseInt(item.cantidad).toLocaleString('es-CL')}</td>
                      <td>${parseInt((item.precioneto*item.cantidad)*0.19).toLocaleString('es-CL')}</td>
                      <td>${parseInt(item.precioneto*item.cantidad).toLocaleString('es-CL')}</td>
                      <td><img onClick={() => handleshoweliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                      {/* <td>{formatNumber(((item.valorventa * 100) / item.precioneto) - 100)}%</td> */}
                  </tr>
                ))}
            </tbody>
        </table>

        <Modal showModal={showModaleliminar} closeModal={handleshoweliminar}>
                <div className='bloque-adv-eliminar'>

                    <strong>Seguro que deseas eliminar el item?</strong>
                    <br />
                    <br />
                    <div className='element-save-bar'>
                        <button className="element-boton Holder" onClick={handleshoweliminar}>Cerrar</button>
                        <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
                    </div>

                </div>
            </Modal>
    </div>
  )
}
