import React, { useState } from 'react'
import { Modal } from '../Modal'
import { DragAndDrop } from '../VentasYFacturas/DragAndDrop';
import { url } from '../../Url';
import { UpdateAdquisicion } from '../../Helpers/UpdateAdquisicion';
import Popup from '../PopUpNotificacion';

export const SubirDocumentos = ({ item, Adquisiciones, setadquisiciones, index, handleModaldocumento, setitem }) => {
  const [showModal, setShowModal] = useState(false);
  const [indexdocumento, setindexdocumento] = useState()
  const [showModaleliminar, setshowModaleliminar] = useState(false)

  const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

  const [posicion, setposicion] = useState(null);
  const handleshoweliminar = (index) => {
    setposicion(index)
    setshowModaleliminar(!showModaleliminar);
  };
  const handleeliminar = async(e) => {
    e.preventDefault()
    let ItemActualizado = { ...item }
    ItemActualizado.documentos[posicion] = { titulo: item.documentos[posicion].titulo, nombre: '', url: "" }
    let AdquisicionesModificado = [...Adquisiciones]
    AdquisicionesModificado[index] = ItemActualizado
    setadquisiciones(AdquisicionesModificado)
    setitem(ItemActualizado)
    let res=await UpdateAdquisicion(ItemActualizado);
    setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "Archivo Eliminado" })
        if (res.succes === false) {
            setmsgpopup({ tipo: 'error', msg: "No se pudo eliminar, intente otra vez" })
        }
        setTimeout(() => {
            setShowPopup(false);
        }, 4000);
    setshowModaleliminar(!showModaleliminar);

  };

  const handleModal = (index) => {

    setindexdocumento(index)
    
    setShowModal(!showModal);
  };
  let handledownloadfile = (nombre) => {



    window.location.href = `${url}/SYSTEM/Downloadfile/${nombre}`;
  }

  return (
    <div className='bloque-dubirdocumentos'>
      {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <h3>Documentos</h3>
      <hr />
      <table className='element-tabla'>

        <tbody>
          {item?.documentos?.map((documento, index) => (
            <tr key={index}>
              <td>{documento.titulo}</td>
              {documento.nombre === "" ? <td><img style={{ cursor: 'pointer', margin: '5px 50px 5px 50px' }} onClick={() => handleModal(index)} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1707758619/pwd8nqsfmzaxazd3s2sd.png" alt="imagen de subir" width={'40px'} /></td> : <td><span onClick={() => handledownloadfile(documento.nombre)} className='modifier-documento'>Ver Documento</span></td>}
              <td><img onClick={() => handleshoweliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>

            </tr>

          ))}
        </tbody>
      </table>

      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
        <button onClick={() => handleModaldocumento()} className='element-boton cerrar'>Cerrar</button>

      </div>
      <Modal showModal={showModal} closeModal={handleModal}>
        <DragAndDrop
          indexdocumento={indexdocumento}
          index={index}
          Adquisiciones={Adquisiciones}
          setadquisiciones={setadquisiciones}
          handleModal={handleModal}
          indexitem={index}
         
          />
      </Modal>
      <Modal showModal={showModaleliminar} closeModal={handleshoweliminar}>
        <div className='bloque-adv-eliminar'>

          <strong>Seguro que deseas eliminar el Archivo?</strong>
          <br />
          <br />
          <div className='element-save-bar'>
            <button className="element-boton Holder" onClick={handleshoweliminar}>Cerrar</button>
            <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
          </div>

        </div>
      </Modal>
    </div>
  )
}
