import React, { useState } from 'react'
import { formatNumber } from '../../Utility/formarnumber';
import { Modal } from '../Modal';
import Select from 'react-select';
import Popup from '../PopUpNotificacion';

const Selectformselect = ({ productos, onChange }) => {
    const opcionesProductos = [...new Set(productos.map((item) => item.nombre))];
    const options = opcionesProductos.map(producto => ({ value: producto, label: producto }));

    const handleChange = (selectedOption) => {

     
       onChange(selectedOption.label==="" ? "" : selectedOption.label);
    };

    return (
        <Select
            options={options}
            onChange={handleChange}
            placeholder="Selecciona un producto"
            isClearable
            className="custom-select"
        />
    );
};

const Selectform = ({ productos, onChange }) => {
    // Extraer valores únicos de la propiedad "producto"
    const opcionesProductos = [...new Set(productos.map((item) => item.nombre))];

    return (
        <select onChange={onChange}>
            <option value="">Selecciona un producto</option>
            {opcionesProductos.map((producto, index) => (
                <option key={index} value={producto}>
                    {producto}
                </option>
            ))}
        </select>
    );
};

let Inputform = ({ name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;

        setform({ ...formulario, [name]: value });



    };

    return (

        <input className='modifier-input'
            type={type}
            name={name}
            value={value}
            onChange={handleChange}

        />
    )
}


export const AgregarProductos = ({ OrdenCompra, setOrdenCompra, handleModal, productos }) => {
    const [showModal, setshowModal] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'Existen campos vacíos' })
    const [producto, setproducto] = useState({ nombre: "", valorventa: "", cantidad: "", precioneto: "", codigo: "" });

    let onChange = (selectedProduct) => {
       
        const Productoencontrado = productos.find(producto => producto.nombre === selectedProduct);
        setproducto({ ...producto, nombre: selectedProduct, precioneto: Productoencontrado?.precioneto, codigo: Productoencontrado?.codigo })
    }
    const handleModalAlerta = (e) => {

        setshowModal(!showModal);

    };
    const handleadd = (e) => {

        
        if(producto.nombre==="" || producto.valorventa==="" || producto.cantidad==="" || producto.precioneto==="" || producto.codigo===""){
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 4000);
            return 0
        }
        let productosFiltrados=OrdenCompra.Productos.filter(productos => productos.nombre.toLowerCase().includes(producto.nombre.toLowerCase()))
        
        if(productosFiltrados.length>0){
            setmsgpopup({...msgpopup,msg:"El producto ya existe en la lista"})
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 4000);
            return 0
        }
        if (parseInt(formatNumber(((producto.valorventa * 100) / producto.precioneto) - 100)) < 0) {
            return handleModalAlerta()
        }
        setOrdenCompra(prevState => ({
            ...prevState,
            Productos: [...prevState.Productos, producto]
        }));

        handleModal()
    }
    return (

        <div>
{showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
            <h3>Agregar Proveedor y Producto</h3>
            <br />
            <hr />

            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>Nombre</td>
                        <td>Valor Unidad</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Selectformselect
                            productos={productos}
                            onChange={onChange}
                        />
                        </td>
                        <td><span>{`$${(parseInt(producto.precioneto) || 0).toLocaleString('es-CL')}`}</span></td>
                        <td></td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <td>Cantidad</td>
                        <td>Valor Venta Unidad</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Inputform
                            name='cantidad'
                            type='number'
                            value={producto.cantidad}
                            setform={setproducto}
                            formulario={producto}
                        /></td>
                        <td>
                            <Inputform
                                name='valorventa'
                                type='number'
                                value={producto.valorventa}
                                setform={setproducto}
                                formulario={producto} />
                        </td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <td>Valor IVA</td>
                        <td>Valor Total</td>
                        <td>Porcentaje Ganancia</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><span>${parseInt((producto.valorventa * producto.cantidad) * 0.19).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span></td>
                        <td><span>${parseInt(producto.valorventa * producto.cantidad).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span></td>

                        <td><span>{formatNumber((((producto.valorventa * 100) / producto.precioneto) - 100 || 0))}%</span></td>
                    </tr>
                </tbody>
            </table>
            <div className='bloque-acciones'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton agregar" onClick={handleadd}>Agregar</button>

            </div>

            <Modal showModal={showModal} closeModal={handleModalAlerta}>
                <div className='bloque-adv-eliminar'>

                    <strong>Valor del producto no válido, favor corregir o contactar a administración para ajustar Valor base</strong>
                    <br />
                    <br />
                    <div className='element-save-bar' style={{ justifyContent: "end" }}>
                        <></>
                        <button className="element-boton cerrar" onClick={handleModalAlerta}>Cerrar</button>
                    </div>

                </div>
            </Modal>
        </div>

    )
}
