import React, { useEffect, useState } from 'react'

import { ListaClientes } from '../../Components/GestAdm/Listacliente'
import { Buscador } from '../../Components/Buscador';

import '../../CSS/Clientes.css'
import { Modal } from '../../Components/Modal';
import { FormAgregarCliente } from '../../Components/GestAdm/FormAgregarCliente';
import { useGetClientes } from '../../Hooks/useGetClientes';
import { LoadingSpinner } from '../../Components/Spiner';

export const Clientes = () => {


    const [showModal, setshowModal] = useState(false)

    const [filtroNombre, setFiltroNombre] = useState('');
    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const [Clientes, setClientes] = useState()

    let { data, loading } = useGetClientes()

    useEffect(() => {

        if (!loading) {
     
            setClientes(data);
            setProductosFiltrados(data);
        }

    }, [data, loading]);


    let handleModal = () => {
        setshowModal(!showModal)
    }
    return (
        <>{loading ? <LoadingSpinner /> : <div className='bloque-render'>
            <h2>Lista de Clientes</h2>
            <hr />
            <br />
            <div className='element-botones-accion'>
                <button className='element-boton agregar-cliente' onClick={handleModal}>agregar Cliente</button>

                <Buscador
                    filtroNombre={filtroNombre}
                    setFiltroNombre={setFiltroNombre}
                    setProductosFiltrados={setProductosFiltrados}
                    Lista={Clientes}
                    placeholder='Buscar por Nombre de producto'
                    Campo='nombre'
                />
            </div>
            <ListaClientes clientes={Clientes} />
            <Modal showModal={showModal} closeModal={handleModal} >
                <FormAgregarCliente handleModal={handleModal} />
            </Modal>
        </div>}</>
    )
}
