import React, { useEffect, useState } from 'react'
import { UpdateUsuario } from '../../Helpers/UpdateUsuario';
import { useGetCargos } from '../../Hooks/useGetCargos';


let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                />
            </label>
        </>)
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}:</label>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}
export const FormEditUsuario = ({ Usuario, Setusuario, handleModal }) => {
    let estado = { "Habilitado": "Habilitado", "Deshabilitado": "Deshabilitado" }
    let { data, loading } = useGetCargos()
    
    const [cargos, setcargos] = useState([])

    useEffect(() => {
        
        if (!loading) {
            setcargos(Object.fromEntries(

                data.map(objeto => [objeto.nombrecargo, objeto.nombrecargo])
                //[objeto.cliente.nombre, objeto.cliente.nombre]
            ))
        }

    }, [data,loading])


    let handleedituser = async () => {

        await UpdateUsuario(Usuario)
        return window.location.reload(true)
    }

    return (
        <div>
            <h3>Agregar Ususario</h3>
            <hr />
            <br />
            <Inputform
                titulo='RUT'
                name='rut'
                type='text'
                value={Usuario.rut}
                setform={Setusuario}
                formulario={Usuario}
            />
            <Inputform
                titulo='Nombres'
                name='nombres'
                type='text'
                value={Usuario.nombres}
                setform={Setusuario}
                formulario={Usuario}
            />
            <Inputform
                titulo='Apellidos'
                name='apellidos'
                type='text'
                value={Usuario.apellidos}
                setform={Setusuario}
                formulario={Usuario}
            />
            <Inputform
                titulo='Login Name'
                name='loginName'
                type='text'
                value={Usuario.loginName}
                setform={Setusuario}
                formulario={Usuario}
            />
            
            <Inputform
                titulo='Contraseña'
                name='password'
                type='password'
                value={Usuario.password}
                setform={Setusuario}
                formulario={Usuario}
            />
            <Selectform
                opciones={!loading ? cargos : {}}
                titulo='Cargo'
                name='cargo'
                opcionSeleccionada={Usuario}
                setOpcionSeleccionada={Setusuario}
            />
            <Selectform
                opciones={estado}
                titulo='Estado'
                name='estado'
                opcionSeleccionada={Usuario}
                setOpcionSeleccionada={Setusuario}
            />
            <br />
            <br />
            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton agregar" onClick={handleedituser}>Actualizar</button>
            </div>

        </div>
    )
}
