import React, { useState } from 'react'
import { CrearCliente } from '../../Helpers/CrearCliente';


let Inputform = ({ titulo, name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                />
            </label>
        </>)
}

export const FormAgregarCliente = ({ handleModal }) => {

    const [Cliente, setCliente] = useState({ rut: '', nombre: '', alias: '', direccion: '', telefono: '',emailempresa:"",contactopago:"",emailencargado:"",telefonoencargdo:"",observacion:"",hidden:false })

    let handlecrearcliente = () => {
        CrearCliente(Cliente)
        window.location.reload(true)
    }

    return (
        <div>
            <h3 style={{ paddingTop: '10px' }}>Agregar Cliente</h3>
            <br />
            <hr />
            <br />
            <Inputform
                titulo='RUT'
                name='rut'
                type='text'
                value={Cliente.rut}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Nombre'
                name='nombre'
                type='text'
                value={Cliente.nombre}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Alias'
                name='alias'
                type='text'
                value={Cliente.alias}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Direccion'
                name='direccion'
                type='text'
                value={Cliente.direccion}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Telefono'
                name='telefono'
                type='text'
                value={Cliente.telefono}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Correo Empresa'
                name='emailempresa'
                type='text'
                value={Cliente.correoempresa}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Encargado de pagos'
                name='contactopago'
                type='text'
                value={Cliente.encargadopago}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Correo encargado de P.'
                name='emailencargado'
                type='text'
                value={Cliente.correoencargadopago}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Tel. encargado de Pago'
                name='telefonocontacto'
                type='text'
                value={Cliente.correoencargadopago}
                setform={setCliente}
                formulario={Cliente}
            />
            <Inputform
                titulo='Observación'
                name='observacion'
                type='text'
                value={Cliente.correoencargadopago}
                setform={setCliente}
                formulario={Cliente}
            />
            
            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton add" onClick={handlecrearcliente}>Agregar</button>
            </div>
        </div>
    )
}
