import React, { useState } from 'react'
import { Modal } from '../Modal'

import { POSTAction } from '../../Helpers/POST'
import Popup from '../PopUpNotificacion'
import { LectorBarras } from './LectorBarras'
import { convertirFecha } from '../../Utility/FormatFecha'
import { AsignacionChofer } from './AsignacionChofer'




export const TablaDespacho = ({ Bodega ,setBodega}) => {

  
  const [showPopup, setShowPopup] = useState(false);
  const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

  const [showeliminar, setshoweliminar] = useState(false)
  const [showModalmodificar, setShowModalmodificar] = useState(false);
  const [Posicion, setPosicion] = useState('')
  const [modalItem, setModalItem] = useState(null);

  let handleModaleliminar = (index) => {
    setshoweliminar(!showeliminar)
    setPosicion(index)
  }
  const handleModalmodificar = (item, index) => {
    if (showModalmodificar === false) {
        setModalItem(item)
    } else {
        setModalItem(null);
    }
    setPosicion(index);
    setShowModalmodificar(!showModalmodificar);
};

  let handleeliminar = async () => {
   
    //let res=({succes:true})
   // let res=await POSTAction(Bodega[Posicion],'Conf/eliminarbanco')
let res={}
    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: " eliminado de forma exitosa" })
    if (!res.succes) {
        setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    setTimeout(() => {
        setShowPopup(false);
    }, 4000);

    let newArray = [...Bodega];
    newArray.splice(Posicion, 1);
    setBodega(newArray);
    
    return res.succes && setshoweliminar(!showeliminar)
  }

  return (
    <>
    {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <table className='Tablaproducto'>
        <thead><tr>
          <td>ID Pedido</td>
          <td>Fecha</td>
          <td>Estado</td>
          <td>Descargar Guia</td>
          <td>Asignar conductor</td>
          {/* <td>Eliminar</td> */}
        </tr></thead>
        <tbody>
          {Bodega?.map((item, index) => (
            <tr key={index}>
              <td style={{width:"100px"}}>{item._id}</td>
             <td>{convertirFecha(item.createdAt)}</td>
             <td>{item.estado}</td>
             <td>Icono Descargar Guia</td>
             <td><img onClick={() => handleModalmodificar(item, index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1723475358/k6br2ionimvrvgq6xliz.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
            </tr>
          ))}
        </tbody>

      </table>


      <Modal showModal={showModalmodificar} closeModal={handleModalmodificar}>
                <AsignacionChofer handleModal={handleModalmodificar} item={modalItem}
             
                />
            </Modal>


      <Modal showModal={showeliminar} closeModal={handleModaleliminar}>
        <div className='bloque-adv-eliminar'>

          <strong>Seguro que deseas eliminar el item?</strong>
          <br />
          <br />
          <div className='element-save-bar'>
            <button className="element-boton Holder" onClick={handleModaleliminar}>Cerrar</button>
            <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
          </div>

        </div>
      </Modal>
     
    </>
  )
}
