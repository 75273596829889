import React, { useEffect, useState } from 'react'
import { UseGetFacturas } from '../../Hooks/useGetFacturas';
import { TablaFacturas } from '../../Components/DespachosyFacturas/TablaFacturas';
import { FilterBox } from '../../Components/DespachosyFacturas/FilterBox';
import "../../CSS/Facturas.css"
export const Facturas = () => {

  const [Facturas, setFacturas] = useState([])
  const [FacturasFiltradas, setFacturasFiltradas] = useState([])
  const [showFilterBox, setShowFilterBox] = useState(false);
  let { data, loading } = UseGetFacturas()

  useEffect(() => {

      if (!loading) {
        setFacturas(data);
        setFacturasFiltradas(data)
      
      }

  }, [data, loading]);

  let toggleFilterBox = () => {
    setShowFilterBox(!showFilterBox);
  };
  return (
    <div className='bloque-render'>
    <h2>Facturas</h2>
    <hr />
    <br /> 
    <div className='element-filtro'><button onClick={toggleFilterBox}><img src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1712525821/duht9k1swxkmyrxxir7h.png" alt="Icon-Filtrar" width={"20px"} /> Filtrar</button></div>
 
      {showFilterBox && <FilterBox  handleModal={toggleFilterBox} setFacturasFiltradas={setFacturasFiltradas} Facturas={Facturas}/>}
    <TablaFacturas Facturas={FacturasFiltradas}/>
    </div>
  )
}
