import React, { useState } from 'react'
import { DeleteProveedor } from '../../Helpers/DeleteProveedor';
import { Modal } from '../Modal';
import { FormEditProveedor } from './FormEditProveedor';
import { ListaProductosProveedor } from './ListaProductosProveedor';


export const ListaProveedor = ({ Proveedores }) => {

    const [showModalmodificar, setShowModalmodificar] = useState(false);
    const [showModaleliminar, setshowModaleliminar] = useState(false)
    const [showModalproductos, setshowModalproductos] = useState(false)
    const [modalItem, setModalItem] = useState(null);

    const handleModalmodificar = (item) => {
        if (showModalmodificar === false) {
            setModalItem(item)
        } else {
            setModalItem(null);
        }
        setShowModalmodificar(!showModalmodificar);
    };

    const handleModalproductos = (item) => {
        if (showModalproductos === false) {
            setModalItem(item)
        } else {
            setModalItem(null);
        }
        setshowModalproductos(!showModalproductos);
    };

    const handleshoweliminar = (item) => {
        setModalItem(item)
        setshowModaleliminar(!showModaleliminar);
    };

    const handleeliminar = async() => {
        await DeleteProveedor(modalItem);
        window.location.reload(true)
    };

    return (
        <div>
            <table className='Tablaproducto'>
                <thead>
                    <tr><td>RUT proveedor</td>
                        <td>Nombre Proveedor</td>
                        <td>Modificar</td>
                        <td>Ver productos</td>
                        <td>Eliminar</td>
                    </tr>
                </thead>
                <tbody>
                    {Proveedores?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.rut}</td>
                            <td>{item.nombre}</td>
                            <td><img onClick={() => handleModalmodificar(item)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/uvowjb2taevrvvb2qcdq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                            <td><img onClick={() => handleModalproductos(item)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1710119967/ozxc0osbpi6mgchsp2lq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                            <td><img onClick={() => handleshoweliminar(item)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>

                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal showModal={showModalmodificar} closeModal={handleModalmodificar}>
                <FormEditProveedor
                    handleModal={handleModalmodificar}
                    Poveedor={modalItem}
                    setPoveedor={setModalItem}
                />
            </Modal>
            <Modal showModal={showModalproductos} closeModal={handleModalproductos}>
                <ListaProductosProveedor
                    handleModal={handleModalproductos}
                    Proveedor={modalItem}
                    setProveedor={setModalItem}
                />
            </Modal>
            <Modal showModal={showModaleliminar} closeModal={handleshoweliminar}>
                <div className='bloque-adv-eliminar'>

                    <strong>Seguro que deseas eliminar el item?</strong>
                    <br />
                    <br />
                    <div className='element-save-bar'>
                        <button className="element-boton Holder" onClick={handleshoweliminar}>Cerrar</button>
                        <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
                    </div>

                </div>
            </Modal>
        </div>
    )
}
