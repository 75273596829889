import React, { useState } from 'react'
import { Modal } from '../Modal';

export const TablaProductosShop = ({form,setform}) => {
    
    const [showModaleliminar, setshowModaleliminar] = useState(false)
    
    const [posicion, setposicion] = useState(null);
    const handleshoweliminar = (index) => {
        setposicion(index)
        setshowModaleliminar(!showModaleliminar);
    };
    const handleeliminar = (e) => {
        e.preventDefault()
        if (form.productos.length > 0) {
            const nuevosProductos = [...form.productos];
            nuevosProductos.splice(posicion, 1);
            setform({ ...form, productos: nuevosProductos });
           
          }
          setshowModaleliminar(!showModaleliminar);
     
    };
    return (
    <>
        
    <table className='Tablaproducto'>
        <thead>
            <tr>
                <td>N</td>
                <td>Nombre Proveedor</td>
                <td>Nombre Producto</td>
               
                <td>Cantidad</td>
                <td>Valor en Dolar</td>
                <td>Valor CLP</td>
                <td>Eliminar</td>
            </tr>
        </thead>
        <tbody>
            
                {form.productos?.map((item,index)=>(
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.Proveedor}</td>
                        <td>{item.Producto}</td>
                        <td>{parseFloat(item.Cantidad).toLocaleString('es-CL')}</td>
                        <td>USD {parseFloat(item.ValorDolar).toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td>${parseFloat((item.ValorDolar)*form.vdolar).toLocaleString('es-CL')}</td>
                        <td><img onClick={() => handleshoweliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                    </tr>
                ))}
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><strong>Total</strong></td>
                    <td> <strong>{`USD ${parseInt(form.opeusd).toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</strong></td>
                    <td><strong>{`$${form.opepesos.toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}</strong></td>
                    <td></td>
                </tr>
            
        </tbody>
    </table>
    <Modal showModal={showModaleliminar} closeModal={handleshoweliminar}>
                <div className='bloque-adv-eliminar'>

                    <strong>Seguro que deseas eliminar el item?</strong>
                    <br />
                    <br />
                    <div className='element-save-bar'>
                        <button className="element-boton Holder" onClick={handleshoweliminar}>Cerrar</button>
                        <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
                    </div>

                </div>
            </Modal>
    </>
  )
}
