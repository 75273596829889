import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../Components/Spiner'
import { TablaShipper } from '../../Components/Configuraciones/TablaShipper'
import { FormAgregarShipper } from '../../Components/Configuraciones/FormAgregarShipper'
import { Modal } from '../../Components/Modal'
import { useGetAction } from '../../Hooks/useGetAction'


export const Shipper = () => {
    let { loading, data } = useGetAction('Conf/obtenershippers')
    const [Shipper, setShipper] = useState([])
    const [showModal, setshowModal] = useState(false)

    useEffect(() => {
     if(!loading){
       
        setShipper(data);
        
     }
    }, [data,loading])
    


    let handleModal = () => {
        setshowModal(!showModal)
    }

    return (
        <>

            {loading ? <LoadingSpinner /> : <div className='bloque-render'>
                <div style={{ display: "flex" }}><h2>Contenedor de Shipper</h2></div>
                <hr />
                <br />
                <button className='element-boton agregar' onClick={handleModal}>agregar Shipper</button>


                <TablaShipper Shipper={Shipper} setShipper={setShipper}/>

                <Modal showModal={showModal} closeModal={handleModal} >
                    <FormAgregarShipper handleModal={handleModal}/>
                </Modal>
            </div>}
        </>
    )
}
