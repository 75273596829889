import React, { useState } from 'react'
const Selectform = ({ opciones, titulo, form, setform, name, Disabled = false }) => {

    const handleChange = (e) => {
        setform({ ...form, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-select-seguimiento'>{titulo}</label>
            <select className='modifier-input' id="miSelect" value={form.estado} onChange={handleChange} disabled={Disabled}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}
export const FilterBox = ({ handleModal, Facturas, setFacturasFiltradas }) => {
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [campoEstado, setCampoEstado] = useState('');

    const handleFiltrar = () => {

        if (campoEstado !== "todos" && fechaDesde!=="" && fechaHasta!=="") {
            const datosFiltrados = filtrarPorFecha(Facturas, fechaDesde, fechaHasta, campoEstado);
            setFacturasFiltradas(datosFiltrados);
        }

    };

    const filtrarPorFecha = (datos, fechaDesde, fechaHasta, campoEstado) => {
        const desde = new Date(fechaDesde);
        const hasta = new Date(fechaHasta);

        return datos.filter((item) => {
            const fechaItem = new Date(item.createAt);
            return fechaItem >= desde && fechaItem <= hasta && (!campoEstado || item.estado === campoEstado);
        });
    };
    return (
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '999' }}>
            <div style={{ position: 'absolute', top: '0', right: '0', width: '30%', height: '100%', backgroundColor: '#fff', padding: '20px', borderRadius: '5px' }}>

                <div className='element-filter-bar'><h2>Filtrar Por</h2>
                    <img onClick={handleModal} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1712525937/hlmyv9pradkhasmviblc.png" alt="icon-close" width={"20px"} height={"18px"} />

                </div>
                <br />
                <span>Rango de Fechas</span>
                <br />
                <hr />
                <br />
                <div className='element-filtro-fecha'>
                    <div className='element-desde'>  <label htmlFor="fechaDesde">Desde</label>
                        <input type="date" id="fechaDesde" value={fechaDesde} onChange={(e) => setFechaDesde(e.target.value)} /></div>
                    <span>&nbsp; - &nbsp;</span>
                    <div className='element-hasta'> <label htmlFor="fechaHasta">Hasta</label>
                        <input type="date" id="fechaHasta" value={fechaHasta} onChange={(e) => setFechaHasta(e.target.value)} /></div>


                </div>
                <br />
                <span>Estados</span>

                <hr />
                <br />
                <div className='element-filtro-estado'>
                    <span>Estado</span>

                    <select id="campoEstado" value={campoEstado} onChange={(e) => setCampoEstado(e.target.value)}>
                        <option value="">Todos</option>
                        <option value="Pendiente">Pendiente</option>
                        <option value="Pago Parcial">Pago Parcial</option>
                        <option value="Pagado">Pagado</option>
                    </select></div>
                <br />
                <br />
                <button className='element-boton agregar' onClick={handleFiltrar}>Filtrar</button>
            </div>
        </div>
    )
}
