import React, { useState } from 'react'
import { Modal } from '../Modal'
import { DeleteUsuario } from '../../Helpers/DeleteUsuario'
import { FormEditUsuario } from './FormEditUsuario'

export const TablaUsuarios = ({ usuarios }) => {

  const [showeliminar, setshoweliminar] = useState(false)
  const [showmodificar, setshowmodificar] = useState(false)
  const [item, setitem] = useState([])
  const [Posicion, setPosicion] = useState('')

  let handleModaleliminar = (index) => {
    setshoweliminar(!showeliminar)
    setPosicion(index)
  }
  let handleModalModificar = (index) => {
    setshowmodificar(!showmodificar)
    setitem(usuarios[index])

  }
  let handleeliminar = async() => {
    await DeleteUsuario(usuarios[Posicion])
    return window.location.reload(true)
  }

  return (
    <>
      <table className='Tablaproducto'>
        <thead><tr>
          <td>RUT</td>
          <td>Nombres</td>
          <td>Apellidos</td>
          <td>Cargo</td>
          <td>Nombre Login</td>
          <td>Estado</td>
          <td>Modificar</td>
          <td>Eliminar</td>

        </tr></thead>
        <tbody>
          {usuarios?.map((item, index) => (
            <tr key={index}>
              <td>{item.rut}</td>
              <td>{item.nombres}</td>
              <td>{item.apellidos}</td>
              <td>{item.cargo}</td>
              <td>{item.loginName}</td>
              <td>{item.estado}</td>
              <td><img onClick={() => handleModalModificar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/uvowjb2taevrvvb2qcdq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
              <td><img onClick={() => handleModaleliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>

            </tr>
          ))}
        </tbody>

      </table>
      <Modal showModal={showeliminar} closeModal={handleModaleliminar}>
        <div className='bloque-adv-eliminar'>

          <strong>Seguro que deseas eliminar el item?</strong>
          <br />
          <br />
          <div className='element-save-bar'>
            <button className="element-boton Holder" onClick={handleModaleliminar}>Cerrar</button>
            <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
          </div>

        </div>
      </Modal>
      <Modal showModal={showmodificar} closeModal={handleModalModificar}>
        <FormEditUsuario Usuario={item} Setusuario={setitem} handleModal={handleModalModificar} />
      </Modal>
    </>
  )
}
