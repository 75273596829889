import React, { useState } from 'react'
import { Modal } from '../Modal';
import { FormEditCliente } from './FormEditCliente';
import { DeleteCliente } from '../../Helpers/DeleteCliente';
import { FormViewCliente } from './FormViewCliente';


export const ListaClientes = ({ clientes }) => {
    const [showModalmodificar, setShowModalmodificar] = useState(false);
    const [showModalver, setShowModaver] = useState(false);
    const [showModaleliminar, setshowModaleliminar] = useState(false)
    const [modalItem, setModalItem] = useState(null);

    const handleModalmodificar = (item) => {
        if (showModalmodificar === false) {
            setModalItem(item)
        } else {
            setModalItem(null);
        }
        setShowModalmodificar(!showModalmodificar);
    };
    const handleshoweliminar = (item) => {
        setModalItem(item)
        setshowModaleliminar(!showModaleliminar);
    }
    let handlever=(item)=>{
        if (showModalver === false) {
            setModalItem(item)
        } else {
            setModalItem(null);
        }
        setShowModaver(!showModalver);
    }

    const handleeliminar = async () => {
        await DeleteCliente(modalItem);
        // window.location.reload(true)
    }

    return (
        <div>
            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>RUT</td>
                        <td>Nombre</td>
                        <td>Contacto Pago</td>
                        <td>Direccion</td>
                        <td>Modificar</td>
                        <td>Eliminar</td>
                        <td>Detalle</td>
                    </tr>
                </thead>
                <tbody>
                    {clientes?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.rut}</td>
                            <td>{item.nombre}</td>
                            <td>{item.contactopago}</td>
                            <td>{item.direccion}</td>

                            <td><img onClick={() => handleModalmodificar(item)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/uvowjb2taevrvvb2qcdq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                            <td><img onClick={() => handleshoweliminar(item)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                            <td><span onClick={() => handlever(item)} className='element-ver'>Ver</span></td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <Modal showModal={showModalmodificar} closeModal={handleModalmodificar}>
                <FormEditCliente

                    handleModal={handleModalmodificar}

                    Cliente={modalItem}
                    setCliente={setModalItem}
                />
            </Modal>
            <Modal showModal={showModalver} closeModal={handlever}>
                <FormViewCliente

                    handleModal={handlever}

                    Cliente={modalItem}
                    setCliente={setModalItem}
                />
            </Modal>

            <Modal showModal={showModaleliminar} closeModal={handleshoweliminar}>
                <div className='bloque-adv-eliminar'>

                    <strong>Seguro que deseas eliminar el item?</strong>
                    <br />
                    <br />
                    <div className='element-save-bar'>
                        <button className="element-boton Holder" onClick={handleshoweliminar}>Cerrar</button>
                        <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
                    </div>

                </div>
            </Modal>
        </div>
    )
}
