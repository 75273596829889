import React from 'react'
import { HeaderModal } from '../HeaderModal';


let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setform({ ...formulario, [name]: value });

  };

  return (
    < >
      <label className='element-input'>

        <div className='modifier-titulo'>{titulo}</div>
        <input className='modifier-input'
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={Disabled}
        />

      </label>
    </>)
}



export const DetalleOrdendeCompra = ({ OrdenCompra, setOrdenCompra, handleModal }) => {

  return (
    <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
     <br />
     <HeaderModal titulo={"Detalle Orden de Compra"} handleModal={handleModal}/>
      <hr />
      <br />
      <div className='bloque-form-order'>
        <Inputform
          titulo='Orden de Compra'
          value={OrdenCompra.ordencompra}
          Disabled={true}
        />
        <Inputform
          titulo="Cliente"
          value={OrdenCompra.cliente.nombre}
          Disabled={true}
        />
        <Inputform
          titulo="Vendedor"
          value={OrdenCompra.vendedor}
          Disabled={true}
        />
        <Inputform
          titulo="Tipo Documento"
          value={OrdenCompra.documento}
          Disabled={true}
        />
        <Inputform
          titulo="Tipo venta"
          value={OrdenCompra.tventa}
          Disabled={true}
        />
        <Inputform
          titulo="Tipo Despacho"
          value={OrdenCompra.tventa}
          Disabled={true}
        />

<Inputform
          titulo="Despacho Gratis"
          value={OrdenCompra.despachogratis}
          Disabled={true}
        />
<p></p>
{OrdenCompra.despachogratis==="NO"&&<Inputform
          titulo='Costo Por Despacho'
          value={OrdenCompra.costodespacho}
          Disabled={true}
        />}
      </div>
      <table className='Tablaproducto'>
            <thead>
                <tr><td>Nombre</td>
                <td>Cantidad</td>
                <td>Valor Total IVA</td>
                <td>Valor Total</td>
                  {/* <td>Porcentaje</td> */}
                  </tr>
            </thead>
            <tbody>
                {OrdenCompra?.Productos?.map((item,index)=>(
                  <tr key={index}>
                      <td>{item.nombre[0].nombre}</td>
                      <td>{parseInt(item.cantidad).toLocaleString('es-CL')}</td>
                      <td>${parseInt((item.precioneto*item.cantidad)*0.19).toLocaleString('es-CL')}</td>
                      <td>${parseInt(item.precioneto*item.cantidad).toLocaleString('es-CL')}</td>
                      {/* <td>{formatNumber(((item.valorventa * 100) / item.precioneto) - 100)}%</td> */}
                  </tr>
                ))}
            </tbody>
        </table>
    </div>
  )
}
