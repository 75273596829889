import React from 'react'
import { FormFactura } from '../../Components/DespachosyFacturas/FormFactura'
import "../../CSS/FormFacturas.css"
export const IngresarFactura = () => {
  return (
    <div className='bloque-render'>
    <h2>Ingreso de facturas</h2>
    <hr />
    <br />

    <FormFactura/>
    </div>
  )
}
