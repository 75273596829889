import { Route, Routes, useLocation } from 'react-router-dom'

import { Adquisicion } from '../Screen/Adquisicion/AdquisicionAsia.jsx'

import { SeguimientoAdquisicion } from '../Screen/Adquisicion/SeguimientoAdquisicion.jsx'

import { ListaProductos } from '../Screen/ControlStock/ListaProductos.jsx'
import { ListaProductosEdit } from '../Screen/ControlStock/ListaProductosEdit.jsx'


import { Clientes } from '../Screen/GestAdm/Clientes.jsx'
import { Productos } from '../Screen/GestAdm/Productos.jsx'
import { Proveedores } from '../Screen/GestAdm/Proveedores.jsx'

import { IngresarVenta } from '../Screen/VentasyFacturas/IngresarVenta.jsx'
import { ListadeOrdenCompra } from '../Screen/VentasyFacturas/ListadeOrdenCompra.jsx'

import { GestionUsuarios } from '../Screen/AdmPersonas/GestionUsuarios.jsx'
import { PermisosyCargos } from '../Screen/AdmPersonas/PermisosyCargos.jsx'


import { Loginpage } from '../Screen/Login/Loginpage.jsx'
import { RedirectScreen } from '../Screen/Login/RedirectScreen.jsx'
import { Home } from '../Screen/Home.jsx'
import { Sidebar } from '../Components/SideBar.jsx'
import { IngresarFactura } from '../Screen/Despacho_Facturas/IngresarFactura.jsx'
import { Facturas } from '../Screen/Despacho_Facturas/Facturas.jsx'
import { Despachos } from '../Screen/Despacho_Facturas/Despachos.jsx'
import { Login } from '../Helpers/Login.jsx'
import { Bancos } from '../Screen/Configuracion/Bancos.jsx'
import { Paises } from '../Screen/Configuracion/Paises.jsx'
import { Shipper } from '../Screen/Configuracion/Shipper.jsx'
import { TipoVenta } from '../Screen/Configuracion/TipodeVenta.jsx'
import { Picking } from '../Screen/Bodega/Picking.jsx'
import { useEffect, useState } from 'react'
import { Envios } from '../Screen/Bodega/Envios.jsx'






export const AppRoutes = () => {
  let location = useLocation();
  const [showsidebar, setShowsidebar] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia('(max-width: 900px)').matches);

  useEffect(() => {
    const handleResize = (event) => {
      setIsSmallScreen(event.matches);
      // Actualiza showsidebar basado en el tamaño de pantalla
  
      setShowsidebar(!event.matches); // Aquí es donde se cambia `showsidebar` a true o false
    };

    const mediaQueryList = window.matchMedia('(max-width: 900px)');
    mediaQueryList.addEventListener('change', handleResize);

    // Ejecutar el efecto inicial
    handleResize(mediaQueryList);

    return () => {
      mediaQueryList.removeEventListener('change', handleResize);
    };
  }, []);

  return (
    <> {location.pathname.includes("login") ? null : <Sidebar showsidebar={showsidebar} setshowsidebar={setShowsidebar} />}
      <div className={location.pathname.includes("login") ? null : showsidebar?"bloque-layout":"bloque-layoutf"}>
        <Routes>
        <Route path='/*' element={<RedirectScreen />} />
        <Route path='Home' element={<Home />} />
         

          {/* Login  */}
          <Route path='Login' element={<Loginpage />} />

          {/* Adquisiciones */}
          <Route path='Generar-adquisicion-comex' element={<Adquisicion />} />
          {/* <Route path="Generar-adquisicion-America" element={<AdquisicionAmerica />} /> */}
          <Route path='Seguimiento-Pedidos' element={<SeguimientoAdquisicion />} />

          {/* Control Stock */}
          <Route path="Lista-Productos" element={<ListaProductos />} />
          <Route path="Manejo-de-Stock" element={<ListaProductosEdit />} />

          {/* Ventas */}
          <Route path='Ingreso-Orden-de-Compra' element={<IngresarVenta />} />
          <Route path='Adm.-de-Orden-de-Compra' element={<ListadeOrdenCompra />} />

          {/* Gestion Administracion */}
          <Route path='Clientes' element={<Clientes />} />
          <Route path='Productos' element={<Productos />} />
          <Route path='Proveedores' element={<Proveedores />} />


          {/*Adm Personas*/}
          <Route path='Usuarios' element={<GestionUsuarios />} />
          <Route path='Permisos-y-Cargos' element={<PermisosyCargos />} />


          {/* Facturas y Despacho */}

          <Route path='Ingresar-Factura' element={<IngresarFactura />} />
          <Route path='Facturas' element={<Facturas/>}/>
          <Route path='Despacho-Bodega' element={<Despachos/>}/>

          {/* Configuraciones */}
          <Route path='Bancos' element={<Bancos/>}/>
          <Route path='Paises' element={<Paises/>}/>
          <Route path='Shippers' element={<Shipper/>}/>
          <Route path='Tipo-Venta' element={<TipoVenta/>}/>
          {/* Bodega */}
          <Route path='Picking' element={<Picking/>}/>
          <Route path='Despachos' element={<Envios/>}/>



          {/* Error page */}
        <Route path='*/paginaError' element={<Login />} />
        
          
          

        </Routes>
      </div>
    </>
  )
}
