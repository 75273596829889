import React, { useEffect, useState } from 'react'
import '../../CSS/Adquisicion.css'
import { FormPedido } from '../../Components/Aquisiciones/FormPedido'
import { useGetProductosProveedor } from '../../Hooks/useGetProductosProveedor'
import { LoadingSpinner } from '../../Components/Spiner'


export const Adquisicion = () => {
  const [Proveedores, setProveedores] = useState()
 
  let { data, loading } = useGetProductosProveedor()
  useEffect(() => {

    if (!loading) {
      setProveedores(data);

    }

  }, [data, loading]);


  const [formulario, setformulario] = useState({
    noperacion: "",
    fecha: "",
    vdolar: "",
    pi: "",
    opepesos: 0,
    opeusd: 0,
    swift: "",
    toperacion: "",
    banco: "",
    cae: "",
    abono: "",
    pabono: "",
    pabonousd: "",
    mcae:"",
    productos: [],
    pais:""
  })
  const handleChange = (event) => {
    setformulario({...formulario,pais:event.target.value});
  };

  return (
<>

{loading?<LoadingSpinner/>:  <div className='bloque-render'>
  <div style={{display:"flex"}}><h2>Adquisiciones Comex</h2></div>
  <hr />
  <br />
  <FormPedido
    formulario={formulario}
    setform={setformulario}
    proveedoresList={Proveedores}
   />
</div>}
</>

  )
}
