import React, { useState } from 'react'
import { DetalleSeguimiento } from './DetalleSeguimiento';

import "../../CSS/Seguimiento.css"
import { UpdateAdquisicion } from '../../Helpers/UpdateAdquisicion';
import { Modal } from '../Modal';

const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-select-seguimiento'>{titulo}</label>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.estado} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}

export const ModificarEstado = ({ adquisicion, setadquisicion, handleModal }) => {
    const [showModalGuardar, setshowModalGuardar] = useState(false)

    let handleshoweGuardar = () => {

        let suma = 0;
        adquisicion.abonos.forEach((abono) => {
            // Verificar si el valor de abono no está en blanco antes de sumarlo
            if (abono.abono.trim() !== "") {
                suma += parseInt(abono.abono);
            }
        });
        
        if (suma >= 100 & adquisicion.estado === 'Producción') {
            setadquisicion({ ...adquisicion, estado: 'En Tránsito' })
            return setshowModalGuardar(!showModalGuardar)
        }
        return handleSubmit()
    }

    let handleSubmit = async () => {

       

        await UpdateAdquisicion(adquisicion)
        handleModal()

        window.location.reload();
    }
    return (
        <div className='bloque-detalle-seguimiento'>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}><button onClick={handleModal} style={{ border: "none", fontSize: "20px", color: "red", padding: "10px" }}><strong>X</strong></button></div>
            <div className='elemento-seguimiento'>
                <h3>Ingreso de Stock</h3>
                {/* <Selectform opciones={{ Produccion: "Produccion", EnTransito: "En Transito", Arribo: "Arribo", Cerrado: "Cerrado" }}
                    titulo="Estado"
                    name="estado"
                    opcionSeleccionada={adquisicion}
                    setOpcionSeleccionada={setadquisicion}
                /> */}

                <p style={{display:"flex"}}><strong>Estado: &nbsp;</strong><p>{adquisicion.estado}</p></p>
                <button onClick={handleshoweGuardar} className='modifier-botton guardar'>Guardar</button>
            </div>
            <hr />
            <br />
            <DetalleSeguimiento
                adquisicion={adquisicion}
                setadquisicion={setadquisicion} />

            <Modal showModal={showModalGuardar} closeModal={handleshoweGuardar}>
                <div className='bloque-adv-eliminar'>

                    <strong>El estado será ACTUALIZADO a 'En Tránsito', ¿desea guardar?</strong>
                    <br />
                    <br />
                    <div className='element-save-bar'>
                        <button className="element-boton cerrar" onClick={()=>{setshowModalGuardar(!showModalGuardar)
                             setadquisicion({ ...adquisicion, estado: 'Producción' })}}>Cerrar</button>
                        <button className="element-boton guardar" onClick={handleSubmit}>Guardar</button>
                    </div>

                </div>
            </Modal>
        </div>
    )
}
