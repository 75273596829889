import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../Components/Spiner'
import { TablaBancos } from '../../Components/Configuraciones/TablaBancos'
import { FormAgregarbanco } from '../../Components/Configuraciones/FormAgregarBanco'
import { Modal } from '../../Components/Modal'
import { useGetAction } from '../../Hooks/useGetAction'


export const Bancos = () => {
    let { loading, data } = useGetAction('Conf/obtenerbancos')
    const [Bancos, setBancos] = useState([])
    const [showModal, setshowModal] = useState(false)

    useEffect(() => {
     if(!loading){
       
        setBancos(data);
        
     }
    }, [data,loading])
    


    let handleModal = () => {
        setshowModal(!showModal)
    }

    return (
        <>

            {loading ? <LoadingSpinner /> : <div className='bloque-render'>
                <div style={{ display: "flex" }}><h2>Contenedor de Bancos</h2></div>
                <hr />
                <br />
                <button className='element-boton agregar' onClick={handleModal}>agregar Banco</button>


                <TablaBancos Banco={Bancos} setBanco={setBancos}/>

                <Modal showModal={showModal} closeModal={handleModal} >
                    <FormAgregarbanco handleModal={handleModal}/>
                </Modal>
            </div>}
        </>
    )
}
