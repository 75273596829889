import React, { useState } from 'react'
import { POSTAction } from '../../Helpers/POST';
import Popup from '../PopUpNotificacion';
import { formatNumber } from '../../Utility/formarnumber';

let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                />
            </label>
        </>)
}

export const ListaEdicion = ({ item, Productos, EditProductos, handleModaldetalles, posicion, setProducto }) => {
    const [edit, setedit] = useState(true)
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

    let handleditar = () => {
        setedit(!edit)
    }
    let handlesave = async () => {
        const nuevoproducto = [...Productos];

        // Verifica si el elemento y el documento existen en el arreglo
        if (nuevoproducto[posicion] && nuevoproducto[posicion]) {
            // Actualiza el nombre del documento en la copia del arreglo
            nuevoproducto[posicion] = item;

            // Actualiza el estado Adquisiciones con la nueva copia del arreglo

            setProducto(nuevoproducto);
        } else {
            console.error('El elemento o documento especificado no existe en el arreglo.');
        }
     
        let res = await POSTAction(nuevoproducto[posicion], 'GADM/actualizaritem')

        setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "Producto actualizado de forma exitosa" })
        if (!res.succes) {
            setmsgpopup({ tipo: 'error', msg: res.estado })
        }
        setTimeout(() => {
            setShowPopup(false);
        }, 4000);
        return res.succes && handleModaldetalles();


    }

    return (
        <div>
            {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
            <div className='element-edit-bar'>
                <span>Detalle</span>
                <img onClick={handleditar} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/uvowjb2taevrvvb2qcdq.png' alt='logo modificar' width={"25px"} />
            </div>
            <hr />
            <br />
            <Inputform
                    titulo='Codigo-SKU'
                    name='codigo'
                    type='text'
                    value={item.codigo}
                    setform={EditProductos}
                    formulario={item}
                />
                <Inputform
                    titulo='Nombre'
                    name='nombre'
                    type='text'
                    value={item.nombre}
                    setform={EditProductos}
                    formulario={item}
                />
                <Inputform
                    titulo='Descripcion'
                    name='descripcion'
                    type='text'
                    value={item.descripcion}
                    setform={EditProductos}
                    
                    formulario={item}
                />
                <Inputform
                    titulo='Descripcion 2'
                    name='descripcion2'
                    type='text'
                    value={item.descripcion2}
                    setform={EditProductos}
                    formulario={item}
                />
                <Inputform
                    titulo='Precio Neto'
                    name='precioneto'
                    type='text'
                    value={formatNumber(item.precioneto)}
                    setform={EditProductos}
                    formulario={item}
                />
                <Inputform
                    titulo='Formato'
                    name='formato'
                    type='text'
                    value={item.formato}
                    setform={EditProductos}
                    formulario={item}
                />
                  <Inputform
                    titulo='Procedencia'
                    name='procedencia'
                    type='text'
                    value={item.procedencia}
                    setform={EditProductos}
                    formulario={item}
                />
                  <Inputform
                    titulo='Volumen'
                    name='volumen'
                    type='text'
                    value={item.forvolumenmato}
                    setform={EditProductos}
                    formulario={item}
                />
                  <Inputform
                    titulo='Peso'
                    name='peso'
                    type='text'
                    value={item.peso}
                    setform={EditProductos}
                    formulario={item}
                />
            <Inputform
                titulo='Stock'
                name='stock'
                type='number'
                value={item.stock}
                setform={EditProductos}
                formulario={item}
                Disabled={edit}
            />
            <Inputform
                titulo='Stock en Tránsito'
                name='stocktransito'
                type='number'
                value={item.stocktransito}
                setform={EditProductos}
                formulario={item}
                Disabled={edit}
            />
       
      
            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModaldetalles}>Cerrar</button>
                <button className="element-boton Guardar" onClick={handlesave}>Guardar</button>
            </div>
        </div>
    )
}
