import React, { useState } from 'react'
import { POSTAction } from '../../Helpers/POST';
import Popup from '../PopUpNotificacion';



let Inputform = ({ titulo, name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                />
            </label>
        </>)
}

export const FormEditbanco = ({ handleModal,banco,setbanco}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })
  
    let handleeditarbanco = async() => {
        let res=await POSTAction(banco,'Conf/actualizarbanco')

    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: "Banco actualizado de forma exitosa" })
    if (!res.succes) {
        setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    setTimeout(() => {
        setShowPopup(false);
    }, 4000);
    return res.succes && window.location.reload();
    }

    return (
        <div>
            {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
            <h3 style={{ paddingTop: '10px' }}>Agregar Banco</h3>
            <br />
            <hr />
            <br />
             <Inputform
                titulo='Nombre'
                name='nombre'
                type='text'
                value={banco.nombre}
                setform={setbanco}
                formulario={banco}
            />
            <Inputform
                titulo='Alias'
                name='alias'
                type='text'
                value={banco.alias}
                setform={setbanco}
                formulario={banco}
            />
            <Inputform
                titulo='Numero de Cuenta'
                name='ncuenta'
                type='text'
                value={banco.ncuenta}
                setform={setbanco}
                formulario={banco}
            />
            
            
            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton add" onClick={handleeditarbanco}>Agregar</button>
            </div>
        </div>
    )
}
