import React from 'react'
import { HeaderModal } from '../HeaderModal'

export const DetalleFactura = ({ Factura, handleModal }) => {
    return (
        <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
           <br />
           <HeaderModal titulo={"Detalle Factura"} handleModal={handleModal}/>
           <hr />

            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>Cliente</td>
                        <td>N Factura</td>
                        <td>N Orden de compra</td>
                        <td>Valor Total</td>
                        {/* <td>Porcentaje</td> */}
                    </tr>
                </thead>
                <tbody>
                    
                        <tr>
                            <td>{Factura.cliente}</td>
                            <td>{Factura.nFactura}</td>
                            <td>{Factura.ordendecompra}</td>
                            <td>{Factura.valortotal}</td>
                            {/* <td>{formatNumber(((item.valorventa * 100) / item.precioneto) - 100)}%</td> */}
                        </tr>
                
                </tbody>
            </table>
            <h4>Detalles Orden De Compra</h4>
            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>Tipo envio</td>
                        <td>Tipo venta</td>
                        <td>Estado compra</td>
                        <td>Vendedor a Cargo</td>
                        {/* <td>Porcentaje</td> */}
                    </tr>
                </thead>
                <tbody>
                    {Factura?.metadataordencompra?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.tipodespacho}</td>
                            <td>{item.tventa}</td>
                            <td>{Factura.estado}</td>
                            <td>{item.vendedor}</td>
                            {/* <td>{formatNumber(((item.valorventa * 100) / item.precioneto) - 100)}%</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            
            </div>
    )
}
