import React, { useEffect, useState } from 'react'
import { Modal } from '../Modal'
import { FormAgregarProductoProveedor } from './FormagregarProductoProveedor'
import { useGetProductosOrdenados } from '../../Hooks/useGetProductosOrdenados'
import { UpdateProveedor } from '../../Helpers/UpdateProveedor'
import { HeaderModal } from '../HeaderModal'
import { FormEditProductoProveedor } from './FormEditProductoProveedor'

export const ListaProductosProveedor = ({ handleModal, Proveedor, setProveedor }) => {
    const [showagregarproducto, setshowagregarproducto] = useState(false)
    const [showModalmodificar, setShowModalmodificar] = useState(false)

    const [ModalItem, setModalItem] = useState('')
    const [showeliminar, setshoweliminar] = useState(false)
    const [posicion, setposicion] = useState('')
    const [productos, setproductos] = useState('')

    let { data, loading } = useGetProductosOrdenados()

    useEffect(() => {

        if (!loading) {
            setproductos(data);
        }

    }, [data, loading]);

    const handleModalModificar = (item) => {
        if (showModalmodificar === false) {
            setModalItem(item)
        } else {
            setModalItem(null);
        }
        setShowModalmodificar(!showModalmodificar);
    };

    const handleModalAgregar = () => {
        setshowagregarproducto(!showagregarproducto)
    }
    const handleModalEliminar = (index) => {
        setshoweliminar(!showeliminar)
        setposicion(index)
    }

    let handleeliminar = async () => {
        let nuevoItem = Proveedor;
        nuevoItem.Productos.splice(posicion, 1);
        await UpdateProveedor(nuevoItem)

        setProveedor(nuevoItem)
        handleModalEliminar(0)
    }

    return (
        <div style={{ padding: "10px" }}>

<div className='element-filter-bar'><h3>Lista de Productos</h3>
<strong>Proveedor:{Proveedor.nombre}</strong>
    <img onClick={handleModal} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1712525937/hlmyv9pradkhasmviblc.png" alt="icon-close" width={"20px"} height={"18px"} style={{cursor:'pointer'}} />
    
</div>
            
            <br />
            <hr />

            <button onClick={handleModalAgregar} className='element-boton agregar-producto-proveedor'>Agregar Producto</button>
            <div style={{width:"100%",textAlign:"end"}}>
                <span>Productos: {parseInt(Proveedor?.Productos.length||0)}</span>
            </div>
            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>Producto MedicPro</td>
                        <td>Poducto Proveedor</td>
                        <td>Descripcion</td>
                        <td>Modificar</td>
                        <td>Eliminar</td>
                    </tr>
                </thead>
                <tbody>
                    {Proveedor?.Productos?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.productomedicpro}</td>
                            <td>{item.productoproveedor}</td>
                            <td>{item.descripcion}</td>
                            <td><img onClick={() => handleModalModificar(item)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/uvowjb2taevrvvb2qcdq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>

                            <td><img onClick={() => handleModalEliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>

                        </tr>
                    ))}
                </tbody>
            </table>


            <Modal showModal={showagregarproducto} closeModal={handleModalAgregar} >
                <FormAgregarProductoProveedor handleModal={handleModalAgregar} productos={productos} proveedor={Proveedor} setproveedor={setProveedor} />
            </Modal>

            <Modal showModal={showModalmodificar} closeModal={() => setShowModalmodificar(!showModalmodificar)}>
                    <FormEditProductoProveedor handleModal={handleModalModificar} proveedor={Proveedor} setproveedor={setProveedor} item={ModalItem} />
            </Modal>


            <Modal showModal={showeliminar} closeModal={handleModalEliminar}>
                <div className='bloque-adv-eliminar'>

                    <strong>Seguro que deseas eliminar el item?</strong>
                    <br />
                    <br />
                    <div className='element-save-bar'>
                        <button className="element-boton Holder" onClick={handleModalEliminar}>Cerrar</button>
                        <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
                    </div>

                </div>
            </Modal>
        </div>
    )
}
