import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../Components/Spiner'
import { TablaPaises } from '../../Components/Configuraciones/TablaPaises'
import { FormAgregarpaises } from '../../Components/Configuraciones/FormAgregarPaises'
import { Modal } from '../../Components/Modal'
import { useGetAction } from '../../Hooks/useGetAction'


export const Paises = () => {
    let { loading, data } = useGetAction('Conf/obtenerPA')
    const [Paises, setPaises] = useState([])
    const [showModal, setshowModal] = useState(false)

    useEffect(() => {
     if(!loading){
       
        setPaises(data);
        
     }
    }, [data,loading])
    


    let handleModal = () => {
        setshowModal(!showModal)
    }

    return (
        <>

            {loading ? <LoadingSpinner /> : <div className='bloque-render'>
                <div style={{ display: "flex" }}><h2>Contenedor de Paises</h2></div>
                <hr />
                <br />
                <button className='element-boton agregar' onClick={handleModal}>agregar País</button>


                <TablaPaises Paises={Paises} setPaises={setPaises}/>

                <Modal showModal={showModal} closeModal={handleModal} >
                    <FormAgregarpaises handleModal={handleModal}/>
                </Modal>
            </div>}
        </>
    )
}
