
import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../Components/Spiner'
import "../../CSS/Picking.css"
import { FormAgregarbanco } from '../../Components/Configuraciones/FormAgregarBanco'
import { Modal } from '../../Components/Modal'
import { useGetAction } from '../../Hooks/useGetAction'
import { TablaBodega } from '../../Components/Bodega/TablaBodega'
import { TablaDespacho } from '../../Components/Bodega/TablaDespachos'


export const Envios = () => {
    let { loading, data } = useGetAction('despachosyfacturas/obtenerdespachos')
    const [Picking, setPicking] = useState([])
    const [showModal, setshowModal] = useState(false)

    useEffect(() => {
     if(!loading){
        setPicking(data);
     }
    }, [data,loading])
    


    let handleModal = () => {
        setshowModal(!showModal)
    }

    return (
        <>

            {loading ? <LoadingSpinner /> : <div className='bloque-render'>
                <div style={{ display: "flex",}}>
                    <h2>Asignacion de Envíos</h2> 
                    

                </div>
                <hr />
                <br />
                <TablaDespacho Bodega={Picking} setBodega={Picking}/>

                <Modal showModal={showModal} closeModal={handleModal} >
                    {/* <FormAgregarbanco handleModal={handleModal}/> */}
                </Modal>
            </div>}
        </>
    )
}
