import React, { useState } from 'react'
import { Modal } from '../Modal';
import { DetalleFactura } from './DetalleFactura';
import { url } from '../../Url';
import { Descargarpdf } from '../../Utility/descargaitem';

export const TablaFacturas = ({Facturas}) => {

  const [showModaldetalles, setShowModaldetalles] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [posicion, setposicion] = useState('')
  const handleModaldetalles = (item, index) => {
    if (showModaldetalles === false) {
        setModalItem(item)
    } else {
        setModalItem(null);
    }
    setposicion(index);
    setShowModaldetalles(!showModaldetalles);
};

  return (
    <div>
        <table className='Tablaproducto'>
        <thead><tr>
          <td>N° Factura</td>
          <td>Nombre Cliente</td>
          <td>Monto Total</td>
          <td>Monto Pendiente</td>
          <td>Estado</td>
          <td>Documento</td>
          <td>Detalle de Factura</td>
        </tr></thead>
        <tbody>
          {Facturas?.map((item, index) => (
            <tr key={index}>
                <td>{item.nFactura}</td>
                <td>{item.cliente}</td>
                <td>{item.valortotal.toLocaleString('es-CL')}</td>
                <td>{item.valortotal.toLocaleString('es-CL')}</td>
                <td>{item.estado}</td>
                <td><img onClick={()=> Descargarpdf(`${url}/SYSTEM/Downloadfile/${item.adjunto.Documento}`)} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1707566776/msk2anyl7kachkqz7lgd.png" alt="Logo Descarga PDF" width={"25px"} style={{ cursor: 'pointer' }} /></td>
                <td><img onClick={() => handleModaldetalles(item, index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/w5qf1ic1jlz0jctrvshx.png' alt='logo detalles' width={"25px"} style={{ cursor: 'pointer' }} /></td>
            </tr>
          ))}
        </tbody>

      </table>
      <Modal showModal={showModaldetalles} closeModal={handleModaldetalles}>
         <DetalleFactura Factura={modalItem} handleModal={handleModaldetalles}/>
      </Modal>
    </div>
  )
}
