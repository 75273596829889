import React, { useEffect, useState } from 'react'
import { Modal } from '../../Components/Modal';
import { TablaProductosVenta } from '../../Components/VentasYFacturas/TablaProductosVenta';
import { AgregarProductos } from '../../Components/VentasYFacturas/AgregarProductos';
import { DragAndDropOrdenCompra } from '../../Components/VentasYFacturas/DragAndDropIngresoCompra';

import "../../CSS/VentasyFacturas.css"
import { useGetInfoVentas } from '../../Hooks/useGetInfoVentas';
import { LoadingSpinner } from '../../Components/Spiner';
import { CreateOrdenDeCompra } from '../../Helpers/CreateOrdenDeCompra';

import Select from 'react-select';
import Popup from '../../Components/PopUpNotificacion';

const SelectFormSearch = ({ opciones, titulo, form, setForm, name }) => {
 
  const options = Object.keys(opciones).map(key => ({
    value: key,
    label: opciones[key]
  }));
  const selectedOption = options.find(option => option.value === form[name]);
  const handleChange = (selectedOption) => {
   
   setForm({ ...form ,[name]: selectedOption ? selectedOption.value : '' });
  };

  return (
    <div className='element-input'>
      <label htmlFor="miSelect" className='modifier-titulo'>{titulo}</label>
      <Select
        id="miSelect"
        value={selectedOption}
        onChange={handleChange}
        options={options}
        placeholder="Selecciona una op"
        className='modifier-input-selct'
        isClearable
      />
    </div>
  );
};


const Selectform = ({ opciones, titulo, form, setform, name }) => {

  const handleChange = (e) => {
    setform({ ...form, [name]: e.target.value });
  };

  return (
    <div className='element-input'>
      <label htmlFor="miSelect" className='modifier-titulo'>{titulo}</label>
      <select className='modifier-input' id="miSelect" value={form.estado} onChange={handleChange}>
        <option value="" >
          Selecciona una opción
        </option>
        {Object.keys(opciones).map((key) => (
          <option key={key} value={key}>
            {opciones[key]}
          </option>
        ))}
      </select>
    </div>
  );
}

let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setform({ ...formulario, [name]: value });

  };

  return (
    < >
      <label className='element-input'>

        <div className='modifier-titulo'>{titulo}</div>
        <input className='modifier-input'
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={Disabled}
        />

      </label>
    </>)
}

export const IngresarVenta = () => {
  const [Modalshow, setModalshow] = useState(false)
  const [Modalshowdocumento, setModalshowdocumento] = useState(false)
  const [OrdenCompra, setOrdenCompra] = useState({ tventa: "", cliente: '', vendedor: '', documento: '', ordencompra: '', adjunto: { Documento: '', Url: '', Nombre: '' }, Productos: [],tipodespacho:"",despachogratis:true,costodespacho:"", hidden: false })
  const [Clientes, setClientes] = useState({})
  const [Vendedores, setVendedores] = useState({})
  const [productos, setProductos] = useState([])
  const [TipoVenta, setTipoVenta] = useState([])

  const [showPopup, setShowPopup] = useState(false);
  const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

  let { data, loading } = useGetInfoVentas();
  useEffect(() => {

    if (!loading) {
      setClientes(data.Clientes);
      setVendedores(data.Usuarios);
      setProductos(data.Productos);
      setTipoVenta(data.Tipoventa);
    }

  }, [data, loading]);

  let TipoDocumento = { 'Orden de Compra': "Orden de Compra", "Boleta": "Boleta", "Sin Documentos": "Sin Documentos" }
  //let TipoVenta = { "Convenio Marco": "Convenio Marco", "Licitación Pública": "Licitación Pública", "Compa ágil": "Compa ágil" ,"Licitación Privados":"Licitación Privados","Distribuidor":"Distribuidor","Particular Privado":"Particular Privado","Particular":"Particular"}
  const handleModal = () => {

    setModalshow(!Modalshow);
  };
  let handleadjunto=()=>{
    setOrdenCompra({...OrdenCompra,adjunto: { Documento: '', Url: '', Nombre: '' }})
  }
  let handleModaldocumento = () => {
    setModalshowdocumento(!Modalshowdocumento)
  }

  let handleaddOrdenCompra = async () => {
    if (OrdenCompra.tventa==="" || OrdenCompra.cliente==="" || OrdenCompra.vendedor==="" || OrdenCompra.documento==="" || OrdenCompra.adjunto.Documento==="" || OrdenCompra.Productos.length===0){
      setmsgpopup({ tipo: 'error', msg: "Existen campos vacíos" })
      setShowPopup(true);
    setTimeout(() => {
        setShowPopup(false);
    }, 4000);

      return 0
    }
    
    let res=await CreateOrdenDeCompra(OrdenCompra)
   
    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: "Orden de venta generada" })
    if (!res.succes) {
        setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    setTimeout(() => {
        setShowPopup(false);
    }, 4000);
    setOrdenCompra({ tventa: "", cliente: '', vendedor: '', documento: '', ordencompra: '', adjunto: { Documento: '', Url: '', Nombre: '' }, Productos: [],tipodespacho:"",despachogratis:true,costodespacho:"", hidden: false })


  }

  return (
    <>{loading ? <LoadingSpinner /> : <div className='bloque-render'>
      {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <h2>Ingreso Orden de Compra</h2>
      <hr />
      <br />
      <div className='bloque-form-order'>
        <SelectFormSearch
          opciones={Clientes}
          titulo="Seleccion de Cliente"
          form={OrdenCompra}
          setForm={setOrdenCompra}
          name='cliente'
        />
        <Selectform
          opciones={Vendedores}
          titulo="Seleccion de Vendedor"
          name='vendedor'
          form={OrdenCompra}
          setform={setOrdenCompra}
        />
        <Selectform
          opciones={TipoDocumento}
          titulo="Tipo Documento"
          name='documento'
          form={OrdenCompra}
          setform={setOrdenCompra}
        />
        <Selectform
          opciones={TipoVenta}
          titulo="Tipo venta"
          name='tventa'
          form={OrdenCompra}
          setform={setOrdenCompra}
        />

        <Inputform
          titulo='Orden de Compra'
          name='ordencompra'
          type='text'
          value={OrdenCompra.ordencompra}
          setform={setOrdenCompra}
          formulario={OrdenCompra}
        />


        <div className='element-subirdocumentos'>
          {OrdenCompra.adjunto.Documento===""?<><span>Adjuntar Documento:</span>
            <img style={{ cursor: 'pointer', margin: '5px 50px 5px 50px' }} onClick={handleModaldocumento} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1707758619/pwd8nqsfmzaxazd3s2sd.png" alt="imagen de subir" width={'40px'} /></>
:<><span>Adjuntar Documento: {OrdenCompra.adjunto.Documento} </span> &nbsp; <img onClick={handleadjunto} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1712525937/hlmyv9pradkhasmviblc.png" alt="icon-close" width={"20px"} height={"18px"} /></>}
        </div>
        <Selectform
          opciones={{"Completo":"Completo","Parcial":"Parcial"}}
          titulo="Tipo Despacho"
          name='tipodespacho'
          form={OrdenCompra}
          setform={setOrdenCompra}
        />

<Selectform
          opciones={{"SI":"SI","NO":"NO"}}
          titulo="Despacho Gratis"
          name='despachogratis'
          form={OrdenCompra}
          setform={setOrdenCompra}
        />

<p></p>
{OrdenCompra.despachogratis==="NO"&&<Inputform
          titulo='Costo Por Despacho'
          name='costodespacho'
          type='text'
          value={OrdenCompra.costodespacho}
          setform={setOrdenCompra}
          formulario={OrdenCompra}
        />}

      </div>

      <br />
      <div>
        <div className='bloque-agregar'> <button className='element-boton agregar' onClick={handleModal}>Agregar Producto</button></div>
        <TablaProductosVenta
          productos={OrdenCompra.Productos}
          OrdenCompra={OrdenCompra}
          setOrdenCompra={setOrdenCompra} />

        <button className='element-boton agregar' onClick={handleaddOrdenCompra}>Generar Orden de compra</button>
        <Modal showModal={Modalshow} closeModal={handleModal}>
          <AgregarProductos
            OrdenCompra={OrdenCompra}
            setOrdenCompra={setOrdenCompra}
            handleModal={handleModal}
            productos={productos} />
        </Modal>


        <Modal showModal={Modalshowdocumento} closeModal={handleModaldocumento}>
          <DragAndDropOrdenCompra
            Adquisiciones={OrdenCompra}
            setadquisiciones={setOrdenCompra}
            handleModal={handleModaldocumento}
            iditem={OrdenCompra.ordencompra} />
        </Modal>
      </div>
    </div>}</>
  )
}
