import React from 'react'
import { useState } from 'react';
import { UpdateProveedor } from '../../Helpers/UpdateProveedor';
import Popup from '../PopUpNotificacion';

let Inputform = ({ titulo, name, type, value, setform, formulario ,Disabled=false}) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });

    };

    return (
        < >
            <label className='element-input'>

                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                />

            </label>
        </>)
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}</label>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}

export const FormEditProductoProveedor = ({ handleModal ,proveedor,setproveedor,item}) => {
   
    const [productoProveedor, setproductoProveedor] = useState(item)
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

    const handleSubmit = async(e) => {
        e.preventDefault()


        const productoBuscado = proveedor.Productos.findIndex(producto => producto.productomedicpro === productoProveedor.productomedicpro);
     
     
        let nuevoproducto ={...proveedor}
        nuevoproducto.Productos[productoBuscado]=productoProveedor
        setproveedor(nuevoproducto)

        let res = await UpdateProveedor(nuevoproducto)
 
        setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "Producto agregado exitosamente" })
        if (!res.succes) {
             setmsgpopup({ tipo: 'error', msg: res.estado })
         }
         setTimeout(() => {
             setShowPopup(false);
         }, 4000);

         setproductoProveedor({ productomedicpro: '', productoproveedor: '', descripcion: '', hidden: false })
        return res.succes && handleModal();
       
    };

    return (
        <form onSubmit={handleSubmit}>
{showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
            <div style={{ padding: '10px' }}>
                <h4 style={{ paddingTop: '10px' }}>Agregar Producto Proveedor</h4>
                <hr />
                <br />

                <Inputform
                    titulo='Nombre Producto MedicPro'
                    name='productomedicpro'
                    type='text'
                    value={productoProveedor.productomedicpro}
                    setform={setproductoProveedor}
                    formulario={productoProveedor}
                    Disabled={true}
                />

                <Inputform
                    titulo='Nombre Producto Proveedor'
                    name='productoproveedor'
                    type='text'
                    value={productoProveedor.productoproveedor}
                    setform={setproductoProveedor}
                    formulario={productoProveedor}
                />


                <Inputform
                    titulo='Descripción'
                    name='descripcion'
                    type='text'
                    value={productoProveedor.descripcion}
                    setform={setproductoProveedor}
                    formulario={productoProveedor}
                />

    
                <div className='element-save-bar'>
                    <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                    <button className="element-boton agregar" onClick={handleSubmit}>Actualizar</button>
                </div>
                <br />
            </div>
        </form>
    )
}
