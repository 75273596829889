import React from 'react'

import '../../CSS/Login.css'
import { FormLogin } from '../../Components/Login/FormLogin'

export const Loginpage = () => {
  
  sessionStorage.removeItem('Permisos');
  sessionStorage.removeItem('metadata');

  return (
    <div className='bloque-login'>

      <div className='bloque-form-login'>
       
        <FormLogin />
      </div>
      <footer className="Footer" >
        <div className="Footer-BotonBusqueda">

          <div className="ContainerConImagen">
            <p>Desarrollado por  </p>
            <img src="https://res.cloudinary.com/dfmgas1qy/image/upload/v1693010201/logotipo-dataintegra-lapiz-2%20%283%29.png.png" alt="logodataintegra" />
          </div>

        </div>
      </footer>
    </div>
  )
}
